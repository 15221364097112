<template>
  <div :class="{ 'opacity-25': loading }">
    <slot></slot>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState('loading', [
      'loading',
    ]),
  },
};
</script>
