<template>
  <div v-if="tabs.length">
    <!--
    <div class="sm:hidden">
      <label for="tabs" class="sr-only">Select a tab</label>
      <select id="tabs" name="tabs" class="block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md">
        <option>My Account</option>
      </select>
    </div>
    -->
    <div class="sm:block"><!-- hidden -->
      <div class="border-b border-gray-200">
        <nav class="-mb-px flex space-x-8 overflow-y-auto" aria-label="Tabs">
          <!-- Current: "border-indigo-500 text-indigo-600", Default: "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300" -->
          <a href="#" class="group inline-flex items-center py-4 px-1 border-b-2 font-medium text-sm"
            v-for="tab in tabs"
            :key="tab.id"
            @click.prevent="switchTab(tab)"
            :class="{
              'border-transparent text-gray-600 hover:text-gray-700 hover:border-gray-300': tab.id !== cActiveTab,
              'border-app-orange': tab.id === cActiveTab,
              'disabled': tab.disabled,
            }"
          >
            <!--
              Heroicon name: solid/user

              Current: "text-indigo-500", Default: "text-gray-400 group-hover:text-gray-500"
            -->
            <!--
            <svg class="text-gray-400 group-hover:text-gray-500 -ml-0.5 mr-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clip-rule="evenodd" />
            </svg>
            -->
            <app-svg class="-ml-0.5 mr-2 h-5 w-5" v-if="tab.icon" :svg="tab.icon"/>
            <span>{{ tab.label }}</span>
          </a>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import AppSvg from '@/components/AppSvg.vue';
import _ from 'lodash';

export default {
  computed: {
    cActiveTab() {
      return this.activeTab ? this.activeTab : this.tabs[0].id;
    },
  },
  components: {
    AppSvg,
  },
  methods: {
    getHashValue(hash) {
      return hash.replace('#', '');
    },
    tabExists(tabId) {
      return _.find(this.tabs, 'id', tabId);
    },
    switchTab(tab) {
      if (tab.disabled) {
        return;
      }

      this.$emit('switchTab', tab.id);

      if (this.updateHash) {
        this.$router.replace({
          hash: tab.id,
        }).catch(() => {});
      }
    },
  },
  mounted() {
    const tabId = this.getHashValue(this.$route.hash);
    if (tabId && this.tabExists(tabId)) {
      this.switchTab({
        id: tabId,
      });
    }
  },
  props: {
    activeTab: {
      type: String,
    },
    tabs: {
      type: Array,
      required: true,
    },
    updateHash: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
