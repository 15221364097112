import Alert from '@/components/Alert.vue';
import AppButton from '@/components/AppButton.vue';
import AppSvg from '@/components/AppSvg.vue';
import Badge from '@/components/Badge.vue';
import Debug from '@/components/Debug.vue';
import PageHeading from '@/components/PageHeading.vue';
import Tabs from '@/components/Tabs.vue';

export default {
  components: {
    Alert,
    AppButton,
    AppSvg,
    Badge,
    Debug,
    PageHeading,
    Tabs,
  },
};
