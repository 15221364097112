<template>
  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
    {{ heading }}
    <slot />
  </th>
</template>

<script>
export default {
  props: {
    heading: {
      type: String,
    },
  },
};
</script>
