var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"border-l-4 p-4",class:{
    'bg-blue-50 border-blue-400': _vm.theme === 'info',
    'bg-green-50 border-green-400': _vm.theme === 'success',
    'bg-yellow-50 border-yellow-400': _vm.theme === 'warning',
  }},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"flex-shrink-0"},[_c('app-svg',{staticClass:"h-5 w-5",class:{
          'text-blue-400': _vm.theme === 'info',
          'text-green-400': _vm.theme === 'success',
          'text-yellow-400': _vm.theme === 'warning',
        },attrs:{"svg":_vm.themeIcon}})],1),_c('div',{staticClass:"ml-3"},[_c('p',{staticClass:"text-sm",class:{
        'text-blue-700': _vm.theme === 'info',
        'text-green-700': _vm.theme === 'success',
        'text-yellow-700': _vm.theme === 'warning',
      }},[_vm._t("default")],2)])])])}
var staticRenderFns = []

export { render, staticRenderFns }