<template>
  <td class="px-6 py-4 whitespace-nowrap text-sm">
    {{ data }}
    <slot/>
  </td>
</template>

<script>
export default {
  props: {
    data: {
      type: String,
    },
  },
};
</script>
