import AppOption from '@/components/AppOption.vue';
import AppOptionDelete from '@/components/Options/Delete.vue';
import AppOptionEdit from '@/components/Options/Edit.vue';
import AppOptions from '@/components/AppOptions.vue';
import AppTable from '@/components/Table.vue';
import AppTd from '@/components/TableData.vue';
import AppTh from '@/components/TableHeader.vue';
import AppThSortable from '@/components/TableHeaderSortable.vue';
import Pagination from '@/components/Pagination.vue';

export default {
  components: {
    AppOption,
    AppOptionDelete,
    AppOptionEdit,
    AppOptions,
    AppTable,
    AppTd,
    AppTh,
    AppThSortable,
    Pagination,
  },
  methods: {
    /*
    updateSort(updatedSort) {
      this.query.sort = updatedSort;
    },
    */
  },
};
