<template>
  <div class="border-l-4 p-4"
    :class="{
      'bg-blue-50 border-blue-400': theme === 'info',
      'bg-green-50 border-green-400': theme === 'success',
      'bg-yellow-50 border-yellow-400': theme === 'warning',
    }"
  >
    <div class="flex">
      <div class="flex-shrink-0">
        <app-svg class="h-5 w-5"
          :svg="themeIcon"
          :class="{
            'text-blue-400': theme === 'info',
            'text-green-400': theme === 'success',
            'text-yellow-400': theme === 'warning',
          }"
        />
      </div>
      <div class="ml-3">
        <p class="text-sm" :class="{
          'text-blue-700': theme === 'info',
          'text-green-700': theme === 'success',
          'text-yellow-700': theme === 'warning',
        }">
          <slot/>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import AppSvg from '@/components/AppSvg.vue';

export default {
  components: {
    AppSvg,
  },
  computed: {
    themeIcon() {
      switch (this.theme) {
        case 'warning':
          return 'exclamation';
        case 'success':
          return 'check-circle';
        case 'info':
        default:
          return 'information-circle';
      }
    },
  },
  props: {
    theme: {
      default: 'info',
      type: String,
    },
  },
};
</script>
