import _ from 'lodash';
import Modal from '@/components/Modal.vue';
import ModalDelete from '@/components/ModalDelete.vue';
import Slideover from '@/components/Slideover.vue';

export default {
  components: {
    Modal,
    ModalDelete,
    Slideover,
  },
  data() {
    return {
      modals: {},
    };
  },
  methods: {
    closeModal(modal) {
      this.modals[modal] = false;
    },
    closeModals() {
      this.modals = _.mapValues(this.modals, () => false);
    },
    openModal(modal) {
      this.modals[modal] = true;
    },
  },
};
