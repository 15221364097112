<template>
    <div
      @keydown.esc="close"
      class="fixed z-10 inset-0 overflow-y-auto"
      v-show="active"
    >
        <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <transition
              enter-active-class="ease-out duration-300"
              leave-active-class="ease-in duration-200"
              enter-class="opacity-0"
              enter-to-class="opacity-100"
              leave-class="opacity-100"
              leave-to-class="opacity-0"
            >
              <div
                @click="close"
                class="fixed inset-0 bg-app-green-darkest bg-opacity-75 transition-opacity"
                v-show="active"
              ></div>
            </transition>

            <span class="hidden sm:inline-block sm:align-middle sm:h-screen">&#8203;</span>

            <transition
              enter-active-class="ease-out duration-300"
              leave-active-class="ease-in duration-200"
              enter-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enter-to-class="opacity-100 translate-y-0 sm:scale-100"
              leave-class="opacity-100 translate-y-0 sm:scale-100"
              leave-to-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div class="inline-block align-bottom bg-white px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
                v-show="active"
              >
                <div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4 z-10">
                  <button type="button" class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    @click="close"
                  >
                    <app-svg class="h-6 w-6" svg="x"/>
                  </button>
                </div>
                <div class="sm:flex sm:items-start">
                  <slot/>
                </div>
                <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse" v-if="$slots.buttons">
                  <slot name="buttons"/>
                  <button type="button" class="mt-3 w-full inline-flex justify-center border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                    @click="close"
                  >
                    {{ $t('cancel') }}
                  </button>
                </div>
              </div>
            </transition>
        </div>
    </div>
</template>

<script>
import AppSvg from '@/components/AppSvg.vue';

export default {
  components: {
    AppSvg,
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
  props: {
    active: {
      type: Boolean,
      required: true,
    },
  },
};
</script>
