<template>
  <div v-if="isDev()">
    <div class="bg-blue-50 p-4 mb-2">
      <div class="flex">
        <div class="flex-shrink-0">
          <app-svg class="h-5 w-5 text-blue-400" svg="information-circle"/>
        </div>
        <div class="ml-3">
          <p class="text-md"><slot/></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppSvg from '@/components/AppSvg.vue';
import Helpers from '@/mixins/helpers';

export default {
  components: {
    AppSvg,
  },
  mixins: [
    Helpers,
  ],
};
</script>
