<template>
  <div class="relative flex justify-end items-center">
    <button
      type="button"
      class="w-8 h-8 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-app-orange"
      @click="active = !active"
    >
      <app-svg class="w-5 h-5" svg="dots-vertical"/>
    </button>

    <transition
      enter-active-class="transition ease-out duration-100"
      leave-active-class="transition ease-in duration-75"
      enter-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <div
        class="mx-3 origin-top-right absolute right-7 top-0 w-48 mt-1 shadow z-10 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none divide-y divide-gray-200"
        v-show="active"
        v-if="$slots.default"
        @click="close"
      >
        <slot/>
      </div>
    </transition>
  </div>
</template>

<script>
import AppSvg from '@/components/AppSvg.vue';

export default {
  beforeDestroy() {
    window.removeEventListener('click', this.maybeClose);
  },
  components: {
    AppSvg,
  },
  data() {
    return {
      active: false,
    };
  },
  methods: {
    close() {
      this.active = false;
    },
    maybeClose(e) {
      if (!this.$el.contains(e.target)) {
        this.close();
      }
    },
  },
  mounted() {
    window.addEventListener('click', this.maybeClose);
  },
};
</script>
