<template>
  <section class="fixed inset-0 overflow-hidden"
    v-show="active"
    @keydown.esc="close"
  >
    <div class="absolute inset-0 overflow-hidden">
      <transition
        enter-active-class="ease-in-out duration-500"
        leave-active-class="ease-in-out duration-500"
        enter-class="opacity-0"
        enter-to-class="opacity-100"
        leave-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div class="absolute inset-0 bg-app-green-darkest bg-opacity-50 transition-opacity" aria-hidden="true"
          v-show="active"
          @click="close"
        ></div>
      </transition>

      <div class="fixed inset-y-0 pl-16 max-w-full right-0 flex">
        <transition
          enter-active-class="transform transition ease-in-out duration-500 sm:duration-700"
          leave-active-class="transform transition ease-in-out duration-500 sm:duration-700"
          enter-class="translate-x-full"
          enter-to-class="translate-x-0"
          leave-class="translate-x-0"
          leave-to-class="translate-x-full"
        >
          <div
            class="w-screen"
            :class="{
              'max-w-md': !wide,
              'max-w-2xl': wide,
            }"
            v-show="active"
          >
            <form-open class="h-full divide-y divide-gray-200 flex flex-col bg-white" @submit="$emit('submit')">
              <div class="flex-1 h-0 overflow-y-auto">
                <div class="py-6 px-4 bg-app-green-dark sm:px-6">
                  <div class="flex items-center justify-between">
                    <slot name="title">
                      <h2 class="text-2xl font-semibold text-white" v-if="title">{{ title }}</h2>
                    </slot>
                    <div class="ml-3 h-7 flex items-center">
                      <button type="button" class="text-white"
                              @click="close"
                      >
                        <span class="sr-only">Close panel</span>
                        <!-- Heroicon name: outline/x -->
                        <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                      </button>
                    </div>
                  </div>
                  <div class="mt-1" v-if="$slots.subtitle">
                    <slot name="subtitle"/>
                  </div>
                </div>
                <div class="flex-1 flex flex-col justify-between">
                  <div class="px-4 sm:px-6">
                    <div class="space-y-6 py-6 space-y-4">
                      <slot />
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex-shrink-0 px-4 py-4 flex justify-end space-x-2" v-if="buttons">
                <button type="button" class="bg-white py-2 px-4 border border-gray-300 shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  @click="close"
                >{{ closeLabel }}</button>
                <slot name="buttons"/>
              </div>
            </form-open>
          </div>
        </transition>
      </div>
    </div>
  </section>
</template>

<script>
import FormOpen from '@/components/FormOpen.vue';

export default {
  components: {
    FormOpen,
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
  props: {
    active: {
      type: Boolean,
      required: true,
    },
    buttons: {
      type: Boolean,
      default: true,
    },
    closeLabel: {
      type: String,
      default() {
        return this.$t('cancel');
      },
    },
    title: {
      type: String,
    },
    wide: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
