// eslint-disable-next-line no-unused-vars
import config from '@/config';

export default {
  methods: {
    appSluggify(string) {
      return string
        .toLowerCase()
        .replace(/ /g, '-')
        .replace(/[^\w-]+/g, '')
        .substring(0, 20);
    },
    isDev() {
      return config.env === 'development';
    },
  },
};
