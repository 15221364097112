<template>
  <button
    class="flex justify-center items-center py-2 px-4 border border-transparent text-sm font-medium"
    :disabled="disabled"
    :class="{
      'opacity-50 cursor-not-allowed': disabled,
      'text-white bg-app-orange focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-app-green-darkish': theme === 'default',
      'text-white bg-blue-400': theme === 'info',
      'bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm': theme === 'delete',
    }"
    @click="$emit('click')"
  >
    <slot>
      <span v-if="!loading">{{ label }}</span>
      <span v-else>{{ label_loading }}</span>
    </slot>
  </button>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default() {
        return this.$t('submit');
      },
    },
    label_loading: {
      type: String,
      default() {
        return this.$t('loading');
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: [Boolean, Number],
      default: false,
    },
    theme: {
      type: String,
      default: 'default',
    },
  },
};
</script>
