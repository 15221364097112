<template>
  <app-option class="hover:text-red-600" svg="trash" :label="$t('delete')" @click="$emit('click')"/>
</template>

<script>
import AppOption from '@/components/AppOption.vue';

export default {
  components: {
    AppOption,
  },
};
</script>
