import config from '@/config';
import CoreEls from '@/mixins/coreEls';
import HasForms from '@/mixins/hasForms';
import HasModals from '@/mixins/hasModals';
import HasQuery from '@/mixins/hasQuery';
import HasTables from '@/mixins/hasTables';
import Helpers from '@/mixins/helpers';
import LoadingState from '@/mixins/loadingState';

export default {
  data() {
    return {
      config,
    };
  },
  methods: {
    // Move?
    updatePage(page) {
      this.query.page = page;
      this.getListings();
    },
    updateSort(updatedSort) {
      this.query.sort = updatedSort;
      this.getListings();
    },
  },
  mixins: [
    CoreEls,
    HasForms,
    HasModals,
    HasQuery,
    HasTables,
    Helpers,
    LoadingState,
  ],
};
