<template>
  <div class="flex flex-col">
    <div class="-my-2 sm:-mx-6 lg:-mx-8">
      <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <table class="min-w-full">
          <thead>
            <tr>
              <slot name="headings"></slot>
            </tr>
          </thead>
          <tbody class="bg-white">
            <tr v-for="(item, index) in items" :key="`item-${index}`" :class="index % 2 === 0 ? 'bg-white' : 'bg-gray-50'">
              <slot name="row" :item="item"/>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      required: true,
      type: Array,
    },
  },
};
</script>
