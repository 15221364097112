<template>
  <modal :active="active" @close="$emit('close')">
    <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
      <app-svg class="h-6 w-6 text-red-600" svg="exclamation"/>
    </div>
    <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
      <slot>
        <h3 class="text-lg leading-6 font-medium text-gray-900" v-if="title">{{ title }}</h3>
        <div class="mt-2" v-if="text">
          <p class="text-sm text-gray-500">{{ text }}</p>
        </div>
      </slot>
    </div>

    <template v-slot:buttons>
      <app-button
        theme="delete"
        :label="$t('delete')"
        :disabled="loading"
        :loading="loading"
        @click="$emit('delete')"
      />
    </template>
  </modal>
</template>

<script>
import CoreEls from '@/mixins/coreEls';
import LoadingState from '@/mixins/loadingState';
import Modal from '@/components/Modal.vue';

export default {
  components: {
    Modal,
  },
  mixins: [
    CoreEls,
    LoadingState,
  ],
  props: {
    active: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
    },
  },
};
</script>
