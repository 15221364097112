<template>
  <div class="mb-4">
    <div class="md:flex md:items-center md:justify-between"
      :class="{
        'pb-4 border-b border-gray-200': !$slots.tabs,
        // 'mb-4': $slots.tabs
      }"
    >
      <div class="flex-1 min-w-0">
        <slot name="heading">
          <h1 class="text-2xl font-semibold" v-if="heading">{{ heading }}</h1>
        </slot>
        <p class="text-sm text-gray-400" v-if="subheading">{{ subheading }}</p>
        <slot/>
      </div>
      <div class="mt-4 flex md:mt-0 md:ml-4 space-x-2">
        <slot name="actions"></slot>
      </div>
    </div>

    <slot name="tabs"/>
  </div>
</template>

<script>
export default {
  props: {
    heading: {
      type: String,
    },
    subheading: {
      type: String,
    },
  },
};
</script>
