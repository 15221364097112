// eslint-disable-next-line no-unused-vars
import _ from 'lodash';

export default {
  methods: {
    /*
    debounceInput: _.debounce(function (callback) {
      this[callback]();
    }, 1000),
    */
    routeQueryUpdated() {
      // This method should be overridden/customised where nec.
    },
    updateQueryParams(params) {
      this.$router.push({
        query: params,
      });
    },
  },
  watch: {
    '$route.query': {
      handler() {
        this.routeQueryUpdated();
      },
      deep: true,
      // immediate: true,
    },
    /*
    query: {
      handler(queryParams) {
        this.updateQueryParams(queryParams);
      },
      deep: true,
    },
    */
  },
};
