<template>
  <app-th>
    <a href="#" class="flex items-center link"
       :class="{
      'text-app-orange': isSorted,
    }"
       @click.prevent="toggleSort"
    >
      <span>{{ label }}</span>

      <app-svg class="ml-2 h-4" svg="dots-horizontal" v-if="!isSorted"/>
      <app-svg class="ml-2 h-4" svg="sort-ascending" v-if="isAsc"/>
      <app-svg class="ml-2 h-4" svg="sort-descending" v-if="isDesc"/>
    </a>
  </app-th>

</template>

<script>
import _ from 'lodash';
import AppSvg from '@/components/AppSvg.vue';
import AppTh from '@/components/TableHeader.vue';

export default {
  components: {
    AppSvg,
    AppTh,
  },
  computed: {
    ascName() {
      return this.name;
    },
    cSort() {
      return Array.isArray(this.sort) ? this.sort : [
        this.sort,
      ];
    },
    descName() {
      return `-${this.name}`;
    },
    isAsc() {
      return _.includes(this.cSort, this.ascName);
    },
    isDesc() {
      return _.includes(this.cSort, this.descName);
    },
    isSorted() {
      return _.intersection(this.cSort, [
        this.ascName,
        this.descName,
      ]).length > 0;
    },
  },
  methods: {
    toggleSort() {
      const updatedSort = _.difference(this.cSort, [
        this.ascName,
        this.descName,
      ]);

      if (!this.isSorted) {
        updatedSort.push(this.ascName);
      } else if (this.isAsc) {
        updatedSort.push(this.descName);
      }

      this.$emit('updateSort', updatedSort);
    },
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    sort: {
      type: [
        Array,
        String,
      ],
      required: true,
    },
  },
};
</script>
