<template>
  <a href="#" class="flex items-center px-4 py-2 text-sm link"
    @click.prevent="$emit('click')"
  >
    <div class="mr-3 h-5 w-5"
      v-if="svg"
    >
      <app-svg :svg="svg"/>
    </div>
    {{ label }}
  </a>
</template>

<script>
import AppSvg from '@/components/AppSvg.vue';

export default {
  components: {
    AppSvg,
  },
  props: {
    label: {
      type: [
        String,
        Number,
      ],
      required: true,
    },
    svg: {
      type: String,
    },
  },
};
</script>
