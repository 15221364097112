import { mapGetters } from 'vuex';
import LoadingWrapper from '@/components/LoadingWrapper.vue';

export default {
  components: {
    LoadingWrapper,
  },
  computed: {
    ...mapGetters('loading', [
      'loading',
    ]),
  },
};
