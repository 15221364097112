<template>
  <app-option svg="pencil-alt" :label="$t('edit')" @click="$emit('click')"/>
</template>

<script>
import AppOption from '@/components/AppOption.vue';

export default {
  components: {
    AppOption,
  },
};
</script>
