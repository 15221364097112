<template>
  <!-- https://codecourse.com/watch/filtering-in-laravel-vue-js?part=277-pagination-wi-vue -->
  <div class="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6 min-w-full"
    :class="{
      'disabled': meta.total === 0,
    }"
  >
    <div class="flex-1 flex justify-between sm:hidden"
      :class="{
        'disabled': meta.last_page === 1,
      }"
    >
      <a
        href="#"
        class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium text-gray-700 bg-white hover:text-gray-500"
        @click.prevent="switched(meta.current_page - 1);"
        :class="{ 'disabled': meta.current_page === 1 }"
      >
        {{ $t('previous') }}
      </a>
      <a
        href="#"
        class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium text-gray-700 bg-white hover:text-gray-500"
        @click.prevent="switched(meta.current_page + 1);"
        :class="{ 'disabled': meta.current_page === meta.last_page }"
      >
        {{ $t('next') }}
      </a>
    </div>
    <div class="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
      <div>
        <p class="text-sm text-gray-700"
           v-html="$t('pagination.text', {
              from: meta.from || 0,
              to: meta.to || 0,
              total: meta.total,
            })"
        ></p>
      </div>
      <div>
        <nav class="relative z-0 inline-flex -space-x-px" aria-label="Pagination">
          <a
            href="#"
            class="relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            @click.prevent="switched(meta.current_page - 1);"
            :class="{ 'disabled': meta.current_page === 1 }"
          >
            <span class="sr-only">{{ $t('pagination.previous') }}</span>
            <!-- Heroicon name: solid/chevron-left -->
            <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                 aria-hidden="true">
              <path fill-rule="evenodd"
                    d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                    clip-rule="evenodd"/>
            </svg>
          </a>

          <a
            href="#"
            class="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50"
            v-for="(x, index) in meta.last_page"
            :key="`pagination-${index}`"
            @click.prevent="switched(x);"
            :class="{ 'disabled': meta.current_page === x }"
          >
            {{ x }}
          </a>

          <a
            href="#"
            class="relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            @click.prevent="switched(meta.current_page + 1);"
            :class="{ 'disabled': meta.current_page === meta.last_page }"
          >
            <span class="sr-only">{{ $t('pagination.next') }}</span>
            <!-- Heroicon name: solid/chevron-right -->
            <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                 aria-hidden="true">
              <path fill-rule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clip-rule="evenodd"/>
            </svg>
          </a>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  /*
  computed: {
    page() {
      return this.$route.query.page ?? 1;
    },
  },
  */
  props: {
    meta: {
      type: Object,
      required: true,
    },
    page: {
      type: [
        Number,
        String,
      ],
      required: true,
    },
    updateQueryParams: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    switched(page) {
      if (`${this.page}` === `${page}` || this.pageIsOutOfBounds(page)) {
        return;
      }

      this.$emit('pageUpdated', page);

      if (this.updateQueryParams) {
        this.$router.push({
          query: {
            ...this.$route.query,
            page,
          },
        });
      }
    },
    pageIsOutOfBounds(page) {
      return page <= 0 || page > this.meta.last_page;
    },
  },
};
</script>
